import React from "react";
import Str from "../../../helpers/Str";
import {FiEdit2} from "react-icons/fi";
import {AiTwotoneDelete} from "react-icons/ai";
export interface BaseTableProps{
    columns: any;
    data?: any[];
    onItemClick?: (item: any) => void;
    loading?: boolean;
    onEdit?: (item: any) => void;
    onDelete?: (item: any) => void;
}


export default function BaseTable({
  columns,
  data,
  onItemClick,
  loading,
  onEdit,
  onDelete
}: BaseTableProps){

    function handleColumn(column: any, item: any) {

        if (column.render){
            return (
                <td className="px-5" width={column.width}>
                    {column.render(item)}
                </td>
            );
        }

        //valida de a coluna tem relacionamento utilizando . exemplo keyArray.variavel
        const relacionamento = column.field.split('.');
        let informacaoTable = item[column.field];

        if (relacionamento.length > 1) {
            const relaci = relacionamento[0];
            const variavel = relacionamento[1];
            informacaoTable = item[relaci][variavel];
        }

        if ((column.type ?? column.columnType) === 'date'){
            return (
                <td className="px-5" width={column.width}>
                    {Str.convertDate(informacaoTable)}
                </td>
            );
        }

        if ((column.type ?? column.columnType) === 'money'){
            return (
                <td className="px-5" width={column.width}>
                    {Number(informacaoTable).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                </td>
            );
        }

        if ((column.type ?? column.columnType) === 'YesOrNo'){
            return (
                <td className="px-5" width={column.width}>
                    {informacaoTable ? 'Sim' : 'Não'}
                </td>
            );
        }

        if (column.columnType === 'CPF'){
            return (
                <td className="px-5" width={column.width}>
                    {Str.addCpfCnpjMask(informacaoTable)}
                </td>
            );
        }

        if (column.columnType === 'phone'){
            return (
                <td className="px-5" width={column.width}>
                    {Str.addPhoneMask(informacaoTable)}
                </td>
            );
        }

        return (
            <td className="px-5 uppercase" width={column.width}>
                {informacaoTable}
            </td>
        );
    }

    return(
        <table className="w-full">
            <thead className="bg-slate-200 border-b">
                <tr>
                    {columns.map((column: { title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }, i: React.Key | null | undefined) => (
                        <th key={i} className="text-start py-3 px-5">
                            {column.title}
                        </th>
                    ))}
                    {(onEdit || onDelete) ?
                    <th className="py-3 px-5 flex justify-end">
                        Ação
                    </th> : ''
                    }
                </tr>
            </thead>
            <tbody>
            {data?.map((item, i) => (
                <tr key={i} className={Str.tw(
                    'border-t',
                    onItemClick ? ' hover:bg-light-dark hover:cursor-pointer active:bg-primary transition' : ''
                )} /*onClick={() => onItemClick && onItemClick(item)}*/>
                    {columns.map((column: any) => handleColumn(column, item))}
                    {(onEdit || onDelete) ?
                        <td className="flex justify-end" >
                            {
                                onEdit ?
                                    <div title={"Editar"} className={'hover:cursor-pointer mr-3'} onClick={() => onEdit(item)}>
                                        <FiEdit2 size={20} className={'hover:text-constancia'}/>
                                    </div>
                                : ''
                            }
                            {
                                onDelete ?
                                    <div title={"Excluir"} className={'hover:cursor-pointer mr-3'} onClick={() => onDelete(item)}>
                                        <AiTwotoneDelete size={20} className={'hover:text-raizes'}/>
                                    </div>
                                : ''
                            }
                        </td> : ''
                    }
                </tr>
            ))}

            </tbody>
        </table>
    )
}